<template>
  <div class="login-wrapper m-t-30">
    <div class="row">
      <!-- START Login Background Pic Wrapper-->
      <div class="col-xl-3 col-md-6 col-sm-12">
      <!-- START Background Pic-->
<!--        <div class="logo-img">-->
<!--          <img src="/images/canvas_logo.png"-->
<!--            data-src="/images/canvas_logo.png"-->
<!--            data-src-retina="/images/canvas_logo.png"-->
<!--            alt=""-->
<!--            class="lazy img-fluid">-->
<!--        </div>-->
      </div>
      <!-- END Login Background Pic Wrapper-->
      <!-- START Login Right Container-->
      <div class="col-xl-6 col-md-6 col-sm-12 bg-white p-t-10 p-b-10">
        <div class="login-boxwrapper">
          <div class="d-flex">
            <img src="/images/kyy-logo.png" style="margin: 0 auto" alt="logo" data-src="/images/kyy-logo.png"
                 data-src-retina="/images/kyy-logo.png" height="100">
          </div>
            <router-view></router-view>
          </div>
      </div>
      <div class="col-xl-3 col-md-6 col-sm-12">
      </div>
      <!-- END Login Right Container-->
    </div>
  </div>

</template>

<script>
export default {
  name: "AuthLayout"
}
</script>
<style scoped lang="scss">
  @import "@assets/pages/login.scss";
</style>
